import { lazy, Suspense, useState } from "react";
import { Modal } from "react-bootstrap";

import { Meta, Layout, Section } from "~/ui";
import { Story, StreamVideoFallback } from "~/components";

import { Routes } from "~/models";

import elliotImg from "~/assets/images/stories/elliot.png";
import erinImg from "~/assets/images/stories/erin.png";
import karrathaImg from "~/assets/images/stories/karratha.png";
import lucyImg from "~/assets/images/stories/lucy.png";
import serapheneImg from "~/assets/images/stories/seraphene.png";
import uwaImg from "~/assets/images/stories/uwa.png";

const StreamVideo = lazy(() => import("../components/StreamVideo"));

type StreamVideoType = {
  videoId: string;
  posterUrl: string;
};

export default function Stories(): JSX.Element {
  const [currentVideo, setCurrentVideo] = useState<StreamVideoType | null>(
    null
  );

  return (
    <Layout>
      <Section
        bgColor="creamGradient"
        baseBgColorTop="primary"
        roundedCorners={{ "top-left": true }}
      >
        <div className="row mb-4">
          <div className="col-12 col-lg-8 mb-4">
            <h1 className="d-flex flex-column text-primary mb-4">
              Youth
              <span className="display-1">Volunteering</span>
              <span className="display-1">Stories</span>
            </h1>
            <p>
              Here is the place to find out what volunteering is all about by
              listening to other people&apos;s experiences.
            </p>
            <p>
              Listen to these inspiring stories and find out what types of roles
              you could do as a volunteer and this will help you to work out
              what issues you are passionate about helping with.
            </p>
          </div>
          {stories.map(({ videoId, title, content, img }) => (
            <div
              key={videoId}
              className="col-12 col-sm-6 col-lg-4 align-self-end mb-4"
            >
              <Story
                img={img}
                title={title}
                content={content}
                isActive={currentVideo?.videoId === videoId}
                handleClick={() =>
                  setCurrentVideo({
                    videoId: videoId,
                    posterUrl: `https://videodelivery.net/${videoId}/thumbnails/thumbnail.jpg?time=4s&height=900`,
                  })
                }
              />
            </div>
          ))}
        </div>

        {typeof window !== "undefined" && currentVideo && (
          <Modal
            show={!!currentVideo}
            onHide={() => setCurrentVideo(null)}
            animation
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Suspense
              fallback={<StreamVideoFallback poster={currentVideo.posterUrl} />}
            >
              <StreamVideo
                src={currentVideo.videoId}
                title="Volunteer story video"
                poster={currentVideo.posterUrl}
                autoplay
              />
            </Suspense>
          </Modal>
        )}
      </Section>
    </Layout>
  );
}

export const Head = () => {
  return <Meta title="Stories" url={Routes.Stories} />;
};

const stories = [
  {
    img: erinImg,
    videoId: "8fe95ef8939c544e3a16e1b5f8e24887",
    title: "Erin",
    content:
      "Closing the education gap has gained her public speaking confidence and a new desire to find a rural job",
  },
  {
    img: serapheneImg,
    videoId: "dcdc5c0b76e4c3758bf2124cbf8fa5db",
    title: "Seraphene & Jayde",
    content:
      "Helping the marine environment and making connections with people from all backgrounds",
  },
  {
    img: uwaImg,
    videoId: "d2c55f2a58aaa0b0529f195845c6dd01",
    title: "UWA team",
    content:
      "Micro plastic collection and helping the homeless differentiating them as dedicated employees",
  },
  {
    img: elliotImg,
    videoId: "a3b1691bc01f3ffb4098d559bc28b4ae",
    title: "Elliott",
    content: "Volunteering in human rights impacted his career path direction",
  },
  {
    img: karrathaImg,
    videoId: "57ed0448402d42527e225f139e73634d",
    title: "Karratha Volunteers",
    content:
      "That fulfillment feeling when you give back to benefit the community",
  },
  {
    img: lucyImg,
    videoId: "ad6b5f7af6be1e43fc7e846d98a80e9f",
    title: "Lucy",
    content:
      "Giving young locals a voice in a council meetings has taught her that what you put effort into does make a difference",
  },
];
